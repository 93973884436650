<template>
  <div class="w-360 fs-0 bg-fff ml-20 topbox">
    <div class="pl-20 h-60 fw-b  w100 pr-20 dp-f jc-sb ai-c" style="border-color:#E2E2E2">
        <div>已添加参数</div>
        <!-- <img src="@/assets/img/template/add.png" alt="" class="cu-p"> -->
    </div>
      <div class="pr-20 pl-20 w100">
      <el-table :data="props.paramsNumber" class="mb-20"  max-height="650px" :header-cell-style="{
        background: '#EAEAEA',
        color:'#666666',
        fontWeight: config.table.fontWeight,
        fontSize:'12px',
      }" row-key="id">
        <el-table-column :align="'center'" prop="parameterName" label="参数名称"  />
        <el-table-column :align="'center'" prop="parameterLength" label="参数长度" width="120" />
        <el-table-column :align="'center'" prop="num" label="已添加个数" width="100" />
      </el-table>
      </div>
  </div>
</template>
<script setup>
import { ref,reactive, onMounted } from "vue";

let props=defineProps({

  paramsNumber:{
    type:Array
  }
})
const state=reactive({
  Notice:[
    {title:'甲方公司名称',length:'30',num:'2'},
    {title:'已方公司名称',length:'30',num:'3'},
    {title:'甲方姓名',length:'30',num:'2'}
  ],
  acIndex:0,
})
onMounted(() => {

});
</script>
<style lang="scss" scoped>
.topbox{
height: calc(100vh - 100px - 128px);
overflow: auto;
}

</style>